import { BottleClickAction, NavigationMode, WineButtonAction } from "@/entities/enums/QuizConfigEnums";
import { IRestaurantQuizConfigResponse } from "@/interfaces/api/responses/IRestaurantQuizConfigResponse";

class WilliAsFeature {
	MainText?: string;
	DenyText?: string;
	ConfirmText?: string;

	SetFocusAutomatically?: boolean;
	SetNoticeAutomatically?: boolean;

	RunInFeatureMode?: boolean;

	constructor(williAsFeature: WilliAsFeature) {
		this.MainText = williAsFeature.MainText;
		this.DenyText = williAsFeature.DenyText;
		this.ConfirmText = williAsFeature.ConfirmText;
		this.SetFocusAutomatically = williAsFeature.SetFocusAutomatically;
		this.SetNoticeAutomatically = williAsFeature.SetNoticeAutomatically;
		this.RunInFeatureMode = williAsFeature.RunInFeatureMode;
	}

	static createFromApiResponse(response: IRestaurantQuizConfigResponse): WilliAsFeature {
		return new WilliAsFeature({
			MainText:
				response.williAsFeatureText ??
				"Hey, ich bin Willi Wein. Lass uns deinen neuen Lieblingswein finden!",
			DenyText: response.williAsFeatureDeny ?? "Nein danke.",
			ConfirmText: response.williAsFeatureAnswer ?? "Ja, Gerne!",
			SetFocusAutomatically: response.williAsFeatureFocus === "true",
			SetNoticeAutomatically: response.williAsFeatureNotice === "true",
			RunInFeatureMode: response.williAsFeatureMode === "true",
		});
	}
}

export class RestaurantQuizConfig {
	EndscreenWineButtonAction?: WineButtonAction;
	Languages?: string[];
	ShowSizeIndicator?: boolean;
	ShowRestartButton?: boolean;
	ShowOnlyVerifiedWines?: boolean;
	showPrinterOption?: boolean;
	showPartnerMatching?: boolean;
	BottleClickAction?: BottleClickAction;
	QrCodeMode?: boolean;
	UrlToBlockFeatureModeOn?: string;
	TransparentMode?: boolean;
	ChatbotVersion?: number;

	OpenEmailDialogAfter?: number;
	EmailDialogText?: string;

	WilliAsFeature?: WilliAsFeature;

	DisableFeedbackQuestion?: boolean;
	DisableFeedbackQuestionOnMobile?: boolean;
	SizeIndicatorGlassText?: string;
	SizeIndicatorBottleText?: string;

	ShopifyShopProductPrefix?: string;

	QrCode?: string;

	ExtraChatBotQuiz?: number;
	ExpertQuiz?: number;
	QuizCountCorrection?: number;
	UseFormalSalutation?: boolean;
	BTGMode?: boolean;
	DarkMode?: boolean;

	NavigationMode?: NavigationMode;

	FullscreenMode?: boolean

	AdvancedWineResultFiltering?: boolean;

	AutoOpenUrls?: string[];
	HideBotOnClose?: boolean;

	SetMatchToUrl?: boolean;

	TasteOffersActive?: boolean;

	CouponUrl?: string;
	CouponImageUrl?: string;
	CouponUrlInfo?: string;
	CouponUrlInfo2?: string;
	CurrencySymbol?: string;
	ShowMatchingPercentage?: boolean;

	EventMode?: boolean;
	DemoLocation?: string;

	WineCardActionText?: string;

	PlayNotificationOnce?: boolean;
	ShowGrapeResultView?: boolean;

	MatchingCallbackActive?: boolean;

	ExhibitionStart?: boolean;

	constructor(restaurantQuizConfig: RestaurantQuizConfig) {
		// TODO: is something like this possible?
		// this = {...restaurantQuizConfig};

		this.EndscreenWineButtonAction = restaurantQuizConfig.EndscreenWineButtonAction;
		this.Languages = restaurantQuizConfig.Languages;
		this.ShowSizeIndicator = restaurantQuizConfig.ShowSizeIndicator;
		this.ShowRestartButton = restaurantQuizConfig.ShowRestartButton;
		this.ShowOnlyVerifiedWines = restaurantQuizConfig.ShowOnlyVerifiedWines;
		this.showPrinterOption = restaurantQuizConfig.showPrinterOption;
		this.BottleClickAction = restaurantQuizConfig.BottleClickAction;
		this.QrCodeMode = restaurantQuizConfig.QrCodeMode;
		this.WilliAsFeature = restaurantQuizConfig.WilliAsFeature;
		this.DisableFeedbackQuestion = restaurantQuizConfig.DisableFeedbackQuestion;
		this.DisableFeedbackQuestionOnMobile = restaurantQuizConfig.DisableFeedbackQuestionOnMobile;
		this.SizeIndicatorGlassText = restaurantQuizConfig.SizeIndicatorGlassText;
		this.SizeIndicatorBottleText = restaurantQuizConfig.SizeIndicatorBottleText;
		this.ShopifyShopProductPrefix = restaurantQuizConfig.ShopifyShopProductPrefix;
		this.UrlToBlockFeatureModeOn = restaurantQuizConfig.UrlToBlockFeatureModeOn;
		this.TransparentMode = restaurantQuizConfig.TransparentMode;
		this.OpenEmailDialogAfter = restaurantQuizConfig.OpenEmailDialogAfter;
		this.EmailDialogText = restaurantQuizConfig.EmailDialogText;
		this.ChatbotVersion = restaurantQuizConfig.ChatbotVersion;
		this.QrCode = restaurantQuizConfig.QrCode;
		this.showPartnerMatching = restaurantQuizConfig.showPartnerMatching;
		this.ExtraChatBotQuiz = restaurantQuizConfig.ExtraChatBotQuiz;
		this.QuizCountCorrection = restaurantQuizConfig.QuizCountCorrection;
		this.UseFormalSalutation = restaurantQuizConfig.UseFormalSalutation;
		this.BTGMode = restaurantQuizConfig.BTGMode;
		this.DarkMode = restaurantQuizConfig.DarkMode;
		this.NavigationMode = restaurantQuizConfig.NavigationMode;
		this.FullscreenMode = restaurantQuizConfig.FullscreenMode;
		this.AdvancedWineResultFiltering = restaurantQuizConfig.AdvancedWineResultFiltering;
		this.AutoOpenUrls = restaurantQuizConfig.AutoOpenUrls;
		this.HideBotOnClose = restaurantQuizConfig.HideBotOnClose;
		this.SetMatchToUrl = restaurantQuizConfig.SetMatchToUrl;
		this.TasteOffersActive = restaurantQuizConfig.TasteOffersActive;
		this.CouponUrl = restaurantQuizConfig.CouponUrl;
		this.CouponUrlInfo = restaurantQuizConfig.CouponUrlInfo;
		this.CouponImageUrl = restaurantQuizConfig.CouponImageUrl;
		this.CouponUrlInfo2 = restaurantQuizConfig.CouponUrlInfo2;
		this.CurrencySymbol = restaurantQuizConfig.CurrencySymbol;
		this.ShowMatchingPercentage = restaurantQuizConfig.ShowMatchingPercentage;
		this.EventMode = restaurantQuizConfig.EventMode;
		this.DemoLocation = restaurantQuizConfig.DemoLocation;
		this.WineCardActionText = restaurantQuizConfig.WineCardActionText;
		this.PlayNotificationOnce = restaurantQuizConfig.PlayNotificationOnce;
		this.ShowGrapeResultView = restaurantQuizConfig.ShowGrapeResultView;
		this.MatchingCallbackActive = restaurantQuizConfig.MatchingCallbackActive;
		this.ExhibitionStart = restaurantQuizConfig.ExhibitionStart;
	}

	static createFromApiResponse(
		restaurantQuizConfigResponse: IRestaurantQuizConfigResponse
	): RestaurantQuizConfig {
		// Map the API Response to the RestaurantQuizConfig

		let endscreenWineButtonAction: WineButtonAction | undefined = undefined;

		if (restaurantQuizConfigResponse.endscreenWineButtonAction?.toUpperCase() === "NAVMAPMODE")
			endscreenWineButtonAction = WineButtonAction.OPENNAVIGATIONMAP;
		else if (restaurantQuizConfigResponse.endscreenWineButtonAction)
			endscreenWineButtonAction =
				WineButtonAction[
					restaurantQuizConfigResponse.endscreenWineButtonAction?.toUpperCase()
				];

		const newRestaurantQuizConfig = {
			EndscreenWineButtonAction: endscreenWineButtonAction,
			Languages: restaurantQuizConfigResponse?.languages
				? restaurantQuizConfigResponse.languages.split(",")
				: undefined,
			ShowSizeIndicator: restaurantQuizConfigResponse.showSizeIndicator === "true",
			ShowRestartButton: restaurantQuizConfigResponse.showRestartButton === "true",
			ShowOnlyVerifiedWines: restaurantQuizConfigResponse.onlyVerified === "true",
			showPrinterOption: restaurantQuizConfigResponse.showPrinterOption === "true",
			BottleClickAction: restaurantQuizConfigResponse?.bottleClickAction
				? BottleClickAction[restaurantQuizConfigResponse?.bottleClickAction.toUpperCase()]
				: BottleClickAction.NONE,
			QrCodeMode: restaurantQuizConfigResponse.qrCodeMode === "true",
			WilliAsFeature: WilliAsFeature.createFromApiResponse(restaurantQuizConfigResponse),
			DisableFeedbackQuestion:
				restaurantQuizConfigResponse.disableFeedbackQuestion === "true",
			DisableFeedbackQuestionOnMobile:
				restaurantQuizConfigResponse.disableFeedbackQuestionMobile === "true",
			SizeIndicatorGlassText: restaurantQuizConfigResponse.SizeIndicatorGlassText,
			SizeIndicatorBottleText: restaurantQuizConfigResponse.SizeIndicatorBottleText,
			ShopifyShopProductPrefix: restaurantQuizConfigResponse.shopifyShopProductPrefix,
			UrlToBlockFeatureModeOn: restaurantQuizConfigResponse.urlToBlockFeatureModeOn,
			TransparentMode: restaurantQuizConfigResponse.transparentMode === "true",
			OpenEmailDialogAfter: restaurantQuizConfigResponse.openEmailDialogInMs ?? restaurantQuizConfigResponse.openEmailDialogAfter,
			EmailDialogText: restaurantQuizConfigResponse.emailDialogText,
			ChatbotVersion: restaurantQuizConfigResponse.chatbotVersion,
			QrCode: restaurantQuizConfigResponse.qrCode,
			showPartnerMatching: restaurantQuizConfigResponse.showPartnerMatching === "true",
			ExtraChatBotQuiz: Number(restaurantQuizConfigResponse.extraChatBotQuiz),
			ExpertQuiz: Number(restaurantQuizConfigResponse.expertQuiz),
			QuizCountCorrection: Number(restaurantQuizConfigResponse.quizCountCorrection),

			UseFormalSalutation: restaurantQuizConfigResponse.useFormalSalutation === "true",
			BTGMode: restaurantQuizConfigResponse.btgMode === "true",
			DarkMode: restaurantQuizConfigResponse.darkMode === "true",
			NavigationMode: restaurantQuizConfigResponse?.navigationMode ? NavigationMode[restaurantQuizConfigResponse?.navigationMode?.toUpperCase()] : NavigationMode.NORMAL,
			FullscreenMode: restaurantQuizConfigResponse.fullscreenMode === "true", 
			AdvancedWineResultFiltering: restaurantQuizConfigResponse.advancedWineResultFiltering === "true",
			AutoOpenUrls: restaurantQuizConfigResponse.autoOpenUrls?.split(","),
			HideBotOnClose: restaurantQuizConfigResponse.hideBotOnClose === "true",
			SetMatchToUrl: restaurantQuizConfigResponse.setMatchToUrl === "true",
			TasteOffersActive: restaurantQuizConfigResponse.tasteOffersActive === "true",
			CouponUrl: restaurantQuizConfigResponse.couponUrl,
			CouponImageUrl: restaurantQuizConfigResponse.couponImageUrl,
			CouponUrlInfo: restaurantQuizConfigResponse.couponUrlInfo,
			CouponUrlInfo2: restaurantQuizConfigResponse.couponUrlInfo2,
			CurrencySymbol: restaurantQuizConfigResponse.currencySymbol ?? "€",
			ShowMatchingPercentage: restaurantQuizConfigResponse.showMatchingPercentage !== "false",
			EventMode: restaurantQuizConfigResponse.eventMode === "true",
			DemoLocation: restaurantQuizConfigResponse.demoLocation,
			WineCardActionText: restaurantQuizConfigResponse.wineCardActionText,
			PlayNotificationOnce: restaurantQuizConfigResponse.playNotificationOnce === "true",
			ShowGrapeResultView: restaurantQuizConfigResponse.showGrapeResultView === "true",
			MatchingCallbackActive: restaurantQuizConfigResponse.matchingCallbackActive === "true",
			ExhibitionStart: restaurantQuizConfigResponse.exhibitionStart === "true",
		} as RestaurantQuizConfig;

		if (
			newRestaurantQuizConfig.WilliAsFeature &&
			restaurantQuizConfigResponse.urlToBlockFeatureModeOn &&
			window.location.href.includes(restaurantQuizConfigResponse.urlToBlockFeatureModeOn)
		)
			newRestaurantQuizConfig.WilliAsFeature.RunInFeatureMode = false;

		return newRestaurantQuizConfig;
	}
}
