
import { defineComponent, PropType } from "vue";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { Answer } from "@/entities/domain/Quiz/Answer";
import getImageFromSource from "@/utils/getImageFromSouce";
import FooterWave from "@/components/Elements/FooterWave.vue";

export default defineComponent({
    name: "ExhibitionStart",
    props: {
        restaurant: {
            type: Object as PropType<Restaurant>,
        },
    },
    emits: ["setAnswer"],
    components: {
        FooterWave,
    },
    computed: {
        baseComponentTranslations() {
            return this.restaurant?.baseComponentTranslations;
        },
        answers(): Answer[] {
            return this.restaurant?.Quiz.getCurrentQuestion().Answers ?? [];
        },
    },
    methods: {
        getImageFromSource,
        answerClicked(answer: Answer) {
            this.$emit("setAnswer", { answerId: answer.Id, answerValue: answer.Value });
        },
    },
});
